import { useEffect, useState } from 'react'

interface UseWebViewReturn {
  isWebView: boolean
}

export const useWebView = (): UseWebViewReturn => {
  const [isWebView, setIsWebView] = useState(false)

  useEffect(() => {
    // Only run on client-side where navigator is available
    const isWebView =
      typeof navigator !== 'undefined' &&
      (/instagram/i.test(navigator.userAgent) ||
        /tiktok|bytedance|bytelocale/i.test(navigator.userAgent))

    setIsWebView(isWebView)
  }, [])

  return {
    isWebView,
  }
}
