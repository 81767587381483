import { signIn } from 'next-auth/react'

import { AppleLogo } from '@snipfeed/icons/custom'
import { Button } from '@snipfeed/tint2'

type AppleAuthButtonProps = {
  isSignup?: boolean
}

export default function AppleAuthButton({
  isSignup,
}: AppleAuthButtonProps): JSX.Element {
  return (
    <Button
      variant="secondary"
      type="button"
      className="w-full mb-6 h-11 bg-white"
      icon={() => <AppleLogo className="mr-3" />}
      onClick={async () => {
        const result = await signIn('apple', {
          callbackUrl: isSignup ? '/signup/your-information' : '/',
        })
        return result
      }}
    >
      {isSignup ? 'Sign up with Apple' : 'Login with Apple'}
    </Button>
  )
}
