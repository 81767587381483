import { signIn } from 'next-auth/react'
import { useState } from 'react'

import { GoogleLogo } from '@snipfeed/icons/custom'
import { Button } from '@snipfeed/tint2'

import { useWebView } from '@/hooks/useWebView'

type GoogleAuthButtonProps = {
  isSignup?: boolean
}

export default function GoogleAuthButton({
  isSignup,
}: GoogleAuthButtonProps): JSX.Element | null {
  const { isWebView } = useWebView()
  const [showWebViewError, setShowWebViewError] = useState(false)

  if (isSignup && isWebView) {
    return null
  }

  return (
    <div className="mb-6">
      <Button
        variant="secondary"
        type="button"
        className="w-full h-11 bg-white"
        icon={() => <GoogleLogo className="mr-3" />}
        onClick={async () => {
          if (isWebView) {
            setShowWebViewError(true)
            return
          }

          const result = await signIn('google', {
            callbackUrl: isSignup ? '/signup/your-information' : '/',
          })
          return result
        }}
      >
        {isSignup ? 'Sign up with Google' : 'Login with Google'}
      </Button>

      {showWebViewError && (
        <p
          className="text-2xs text-cherry-800 first-letter:capitalize"
          role="alert"
        >
          Please open this page in your default browser to sign in with Google
        </p>
      )}
    </div>
  )
}
