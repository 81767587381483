import type { SVGProps } from 'react'
const SvgLinkExternal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.75 10.03c-.28 0-.5.22-.5.5v2.58c0 .17-.14.32-.32.32H2.71c-.18 0-.32-.14-.32-.32V2.89c0-.17.14-.32.32-.32h2.55c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H2.71c-.73 0-1.32.59-1.32 1.32v10.22c0 .73.59 1.32 1.32 1.32h10.22c.73 0 1.32-.59 1.32-1.32v-2.58c0-.28-.22-.5-.5-.5Z"
    />
    <path
      fill="currentColor"
      d="M13.75 1.57H7.54c-.28 0-.5.22-.5.5s.22.5.5.5h5.01L7.7 7.42c-.2.2-.2.51 0 .71a.485.485 0 0 0 .7 0l4.84-4.84v4.99c0 .28.22.5.5.5s.5-.22.5-.5V2.07c0-.28-.22-.5-.5-.5h.01Z"
    />
  </svg>
)
export default SvgLinkExternal
