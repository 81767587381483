import { Store } from './storage'

let store: Store | undefined

export function getStore(): Store {
  if (store) {
    return store
  }

  return new Store(['localStorage', 'cookie', 'memory'])
}

export { Store, Cookie, LocalStorage, SessionStorage } from './storage'
