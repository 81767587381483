import jar from 'js-cookie'

export type StoreType = 'cookie' | 'localStorage' | 'memory' | 'sessionStorage'

type StorageObject = Record<string, unknown>

interface Storage {
  get<T>(key: string): T | null
  set<T>(key: string, value: T | null): void
  remove(key: string): void
  type: StoreType
}

export interface CookieOptions {
  expires?: number
  path?: string
  secure?: boolean
  sameSite?: string
}

class Memory implements Storage {
  private cache: Record<string, unknown> = {}
  private static instance: Memory

  public static getInstance(): Memory {
    if (!Memory.instance) {
      Memory.instance = new Memory()
    }

    return Memory.instance
  }

  get<T>(key: string): T | null {
    return this.cache[key] as T | null
  }

  set<T>(key: string, value: T | null): void {
    this.cache[key] = value
  }

  remove(key: string): void {
    delete this.cache[key]
  }

  get type(): StoreType {
    return 'memory'
  }
}

export class Cookie implements Storage {
  static available(): boolean {
    let cookieEnabled =
      typeof window !== 'undefined' && window.navigator.cookieEnabled

    if (!cookieEnabled) {
      jar.set('snip:cookies', 'test')
      cookieEnabled =
        typeof window !== 'undefined' &&
        document.cookie.includes('snip:cookies')
      jar.remove('snip:cookies')
    }

    return cookieEnabled
  }

  static get defaults(): CookieOptions {
    return {
      expires: 1 / 24 / 2, // 30 minutes (in days)
      path: '/',
      sameSite: 'Lax',
      secure: true,
    }
  }

  private options: Required<CookieOptions>

  constructor(options: CookieOptions = Cookie.defaults) {
    this.options = {
      ...Cookie.defaults,
      ...options,
    } as Required<CookieOptions>
  }

  private opts(): jar.CookieAttributes {
    return {
      sameSite: this.options.sameSite as jar.CookieAttributes['sameSite'],
      expires: this.options.expires,
      path: this.options.path,
      secure: this.options.secure,
    }
  }

  get<T>(key: string): T | null {
    try {
      const value = jar.get(key)

      if (!value) {
        return null
      }

      try {
        return JSON.parse(value)
      } catch (_e) {
        return value as unknown as T
      }
    } catch (_e) {
      return null
    }
  }

  set<T>(key: string, value: T): void {
    if (typeof value === 'string') {
      jar.set(key, value, this.opts())
    } else if (value === null) {
      jar.remove(key, this.opts())
    } else {
      jar.set(key, JSON.stringify(value), this.opts())
    }
  }

  remove(key: string): void {
    return jar.remove(key, this.opts())
  }

  get type(): StoreType {
    return 'cookie'
  }
}

const localStorageWarning = (key: string, state: 'full' | 'unavailable') => {
  console.warn(`Unable to access ${key}, localStorage may be ${state}`)
}

const sessionStorageWarning = (key: string, state: 'full' | 'unavailable') => {
  console.warn(`Unable to access ${key}, sessionStorage may be ${state}`)
}

export class LocalStorage implements Storage {
  static available(): boolean {
    const test = 'test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (_e) {
      return false
    }
  }

  get<T>(key: string): T | null {
    try {
      const val = localStorage.getItem(key)
      if (val === null) {
        return null
      }
      try {
        return JSON.parse(val)
      } catch (_e) {
        return val as any as T
      }
    } catch (_err) {
      localStorageWarning(key, 'unavailable')
      return null
    }
  }

  set<T>(key: string, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch {
      localStorageWarning(key, 'full')
    }
  }

  remove(key: string): void {
    try {
      return localStorage.removeItem(key)
    } catch (_err) {
      localStorageWarning(key, 'unavailable')
    }
  }

  get type(): StoreType {
    return 'localStorage'
  }
}

export class SessionStorage implements Storage {
  static available(): boolean {
    const test = 'test'
    try {
      sessionStorage.setItem(test, test)
      sessionStorage.removeItem(test)
      return true
    } catch (_e) {
      return false
    }
  }

  get<T>(key: string): T | null {
    try {
      const val = sessionStorage.getItem(key)
      if (val === null) {
        return null
      }
      try {
        return JSON.parse(val)
      } catch (_e) {
        return val as any as T
      }
    } catch (_err) {
      sessionStorageWarning(key, 'unavailable')
      return null
    }
  }

  set<T>(key: string, value: T): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch {
      sessionStorageWarning(key, 'full')
    }
  }

  remove(key: string): void {
    try {
      return sessionStorage.removeItem(key)
    } catch (_err) {
      sessionStorageWarning(key, 'unavailable')
    }
  }

  get type(): StoreType {
    return 'sessionStorage'
  }
}

type StorageOptions = {
  cookie: Cookie | undefined
  localStorage: LocalStorage | undefined
  memory: Memory
  sessionStorage: SessionStorage | undefined
}

export function getAvailableStorageOptions(
  cookieOptions?: CookieOptions
): StorageOptions {
  return {
    cookie: Cookie.available() ? new Cookie(cookieOptions) : undefined,
    localStorage: LocalStorage.available() ? new LocalStorage() : undefined,
    sessionStorage: SessionStorage.available()
      ? new SessionStorage()
      : undefined,
    memory: Memory.getInstance(),
  }
}

export class Store<Data extends StorageObject = StorageObject> {
  private enabledStores: StoreType[]
  private storageOptions: StorageOptions

  constructor(stores: StoreType[] = ['localStorage', 'cookie', 'memory']) {
    this.enabledStores = stores
    this.storageOptions = getAvailableStorageOptions()
  }

  private getStores(storeTypes: StoreType[] | undefined): Storage[] {
    const stores: Storage[] = []
    this.enabledStores
      .filter((i) => !storeTypes || storeTypes?.includes(i))
      .forEach((storeType) => {
        const storage = this.storageOptions[storeType]
        if (storage !== undefined) {
          stores.push(storage)
        }
      })

    return stores
  }

  public getAndSync<K extends keyof Data>(
    key: K,
    storeTypes?: StoreType[]
  ): Data[K] | null {
    const val = this.get(key, storeTypes)

    // legacy behavior, getAndSync can change the type of a value from number to string (AJS 1.0 stores numerical values as a number)
    const coercedValue = (typeof val === 'number' ? val.toString() : val) as
      | Data[K]
      | null

    this.set(key, coercedValue, storeTypes)

    return coercedValue
  }

  public get<K extends keyof Data>(
    key: K,
    storeTypes?: StoreType[]
  ): Data[K] | null {
    let val: Data[K] | null = null

    for (const store of this.getStores(storeTypes)) {
      val = store.get<Data[K]>(key as string)
      if (val) {
        return val
      }
    }
    return null
  }

  public set<K extends keyof Data>(
    key: K,
    value: Data[K] | null,
    storeTypes?: StoreType[]
  ): void {
    for (const store of this.getStores(storeTypes)) {
      store.set(key as string, value)
    }
  }

  public clear<K extends keyof Data>(key: K, storeTypes?: StoreType[]): void {
    for (const store of this.getStores(storeTypes)) {
      store.remove(key as string)
    }
  }
}
